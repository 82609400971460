const TOKEN_KEY = 'auth-token'
const useTokenState = () => useState('auth-token', () => localStorage.getItem(TOKEN_KEY) || null)

// Simple function to decode the useful part of the jwt.
const jwtDecode = (token) => {
  if (!token) return null
  return JSON.parse(atob(token.split('.')[1]))
}

export function useAuthSession() {
  const token = useTokenState()
  return {
    token,
    setToken,
    clearToken,
    loggedIn: computed(() => !!token.value),
    userId: computed(() => jwtDecode(token.value)?.id || ''),
    name: computed(() => jwtDecode(token.value)?.name || ''),
    email: computed(() => jwtDecode(token.value)?.email || ''),
    role: computed(() => jwtDecode(token.value)?.role || 'user'),
    userOrgId: computed(() => jwtDecode(token.value)?.organizationId || ''),
  }
}

function setToken(newToken) {
  useTokenState().value = newToken
  localStorage.setItem(TOKEN_KEY, newToken)
}

function clearToken() {
  useTokenState().value = null
  localStorage.removeItem(TOKEN_KEY)
}
