export enum Environments {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production'
}

// TODO: I don't love the position of these functions.
// Currently this is the only folder that is "shared" between client and
// server. Maybe I can create a "helpers" folder?
export function isDev() {
  return import.meta.dev
}

export enum ClientStatusOptions {
  NEW_CLIENT = 'New Client',
  COMPLETE = 'Filed - Complete',
  MISSING_DOCS = 'Missing Documentation',
  PROFORMA_CLIENT = 'Proforma Client',
  READY_TO_FILE = 'Ready to File'
}

// TODO: this is a temporary hack. We should be pulling this from the db.
export enum AccountantOptions {
  GORAN = 'Goran G.',
  CHRIS = 'Chris E.',
  DEEP = 'Deep'
}

export enum FilingStatusOptions {
  SINGLE = 'Single',
  MARRIED_SEPARATELY = 'Married - Filing Separately',
  MARRIED_JOINTLY = 'Married - Filing Jointly',
  HEAD_OF_HOUSEHOLD = 'Head of Household',
  QUALIFYING_SURVIVING_SPOUSE = 'Qualifying Surviving Spouse (Widow)'
}

export enum OrganizationStatus {
  INVITED = 'Invited',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}

export const SearchDebounce = 1000

export const DefaultPassword = 'asdfasdf'

export enum UserRoles {
  SUPERADMIN = 'superadmin',
  ADMIN = 'admin',
  PREPARER = 'preparer',
  REVIEWER = 'reviewer'
}

export const SimpliFlowOrgId = '01010101424242424242424242'
